<template>
    <div id="pdf-viewer">
        <div class="controls">
            <button @click="zoomIn">放大</button>
            <button @click="zoomOut">缩小</button>
            <button @click="exportImage">导出当前可见页面</button>
            <button @click="exportAllPages">导出全部页面</button>
            <button @click="goback">返回</button>
        </div>
        <div class="pdf-container" ref="pdfContainer" >

        </div>
    </div>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist';
import { showErrorToast } from '@/utils/toast';


pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.worker.min.js';

export default {
    data() {
        return {
            pdf: null,
            pages: [],
            scale: 1.0,
            scaleFactor: 5,
            initialDistance: 0, // 用于存储初始触摸距离
            isZooming: false,
            isPanning: false,
            startX: 0,
            startY: 0,
            loadedPages: new Set(),// 用于跟踪已加载的页面
            totalPages: 0 // 用于存储总页数
        };
    },
    methods: {
        async loadPdf() {
            const urlParams = new URLSearchParams(window.location.search);
            const pdfFilePath = urlParams.get('file');

            if (!pdfFilePath) {
                console.error('No PDF file specified in the URL.');
                showErrorToast('未指定PDF文件。请检查URL中的文件参数。');
                return;
            }
            // 清理之前的 PDF 内容
            this.clearPdf();
            try {
                const loadingTask = pdfjsLib.getDocument(pdfFilePath);
                this.pdf = await loadingTask.promise;
                this.totalPages = this.pdf.numPages; // 获取总页数
                console.log('PDF loaded successfully');
                this.renderPages(); // 初始渲染第一页
            } catch (error) {
                console.error('Error loading PDF:', error);
                showErrorToast('加载PDF文件时出错。请确保文件存在并且路径正确。');
            }
        },
        clearPdf() {
            const container = this.$refs.pdfContainer;

            // 清空容器
            while (container.firstChild) {
                container.removeChild(container.firstChild);
            }

            // 清空已加载页面的集合
            this.loadedPages.clear();
            this.pdf = null; // 清空当前 PDF 对象
            this.scale = 1.0; // 重置缩放比例
        },
        async renderPages() {
            if (!this.pdf) {
                console.error('No PDF loaded. Cannot render pages.');
                return; // Exit if no PDF is loaded
            }

            const container = this.$refs.pdfContainer;

            // 清空容器
            while (container.firstChild) {
                container.removeChild(container.firstChild);
            }
            // 清空已加载页面的集合
            this.loadedPages.clear();
            // 渲染所有页面
            for (let i = 1; i <= this.totalPages; i++) {
                await this.renderPage(i); // 渲染每一页
            }
        },
        getVisiblePageIndices() {
            const container = this.$refs.pdfContainer;
            const canvases = container.getElementsByTagName('canvas');
            const visibleIndices = [];

            for (let i = 0; i < canvases.length; i++) {
                const rect = canvases[i].getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();
                if (rect.top < containerRect.bottom && rect.bottom > containerRect.top) {
                    visibleIndices.push(i + 1); // 页码从1开始
                }
            }

            return visibleIndices;
        },
        async renderPage(pageNumber) {
            if (this.loadedPages.has(pageNumber)) return; // 如果页面已加载，则返回

            const page = await this.pdf.getPage(pageNumber);
            this.loadedPages.add(pageNumber); // 标记页面为已加载

            const canvas = document.createElement('canvas');
            this.$refs.pdfContainer.appendChild(canvas); // Append the canvas to the container
            const context = canvas.getContext('2d');

            const viewport = page.getViewport({ scale: this.scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            await page.render({ canvasContext: context, viewport: viewport }).promise;
        },
        setupObserver() {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const pageNumber = parseInt(entry.target.dataset.page);
                        this.renderPage(pageNumber); // 当页面进入视口时渲染
                    }
                });
            });

            // 观察每一页
            for (let i = 1; i <= this.totalPages; i++) {
                const pageDiv = document.createElement('div');
                pageDiv.dataset.page = i; // 设置数据属性以标识页面
                this.$refs.pdfContainer.appendChild(pageDiv); // 将 div 添加到容器
                observer.observe(pageDiv); // 观察该 div
            }
        },
        zoomIn() {
            if (!this.pdf) {
                showErrorToast('请先加载PDF文件。');
                return;
            }
            this.scale *= 1.1;
            this.renderPages();
        },
        zoomOut() {
            if (!this.pdf) {
                showErrorToast('请先加载PDF文件。');
                return;
            }
            this.scale /= 1.1;
            this.renderPages();
        },
        exportImage() {
            const container = this.$refs.pdfContainer;
            const canvases = container.getElementsByTagName('canvas');
            const exportCanvas = document.createElement('canvas');
            const exportContext = exportCanvas.getContext('2d');

            let currentHeight = 0;
            let visibleCanvases = [];


            Array.from(canvases).forEach((canvas) => {
                const rect = canvas.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();


                if (rect.top < containerRect.bottom && rect.bottom > containerRect.top) {
                    visibleCanvases.push(canvas);
                }
            });


            const totalHeight = visibleCanvases.reduce((sum, canvas) => sum + canvas.height, 0);
            exportCanvas.width = canvases[0].width;
            exportCanvas.height = totalHeight;


            visibleCanvases.forEach((canvas) => {
                exportContext.drawImage(canvas, 0, currentHeight);
                currentHeight += canvas.height;
            });

            const link = document.createElement('a');
            link.download = 'exported_visible_pages.png';
            link.href = exportCanvas.toDataURL('image/png');
            link.click();
        },
        exportAllPages() {
            const container = this.$refs.pdfContainer;
            const canvases = container.getElementsByTagName('canvas');
            const exportCanvas = document.createElement('canvas');
            const exportContext = exportCanvas.getContext('2d');

            let currentHeight = 0;


            const totalHeight = Array.from(canvases).reduce((sum, canvas) => sum + canvas.height, 0);
            exportCanvas.width = canvases[0].width;
            exportCanvas.height = totalHeight;


            Array.from(canvases).forEach((canvas) => {
                exportContext.drawImage(canvas, 0, currentHeight);
                currentHeight += canvas.height;
            });


            const link = document.createElement('a');
            link.download = 'exported_all_pages.png';
            link.href = exportCanvas.toDataURL('image/png');
            link.click();
        },
        goback() {
            history.back();
        },
        handleTouchStart(event) {
            if (event.touches.length === 2) {
                this.isZooming = true; // 开始缩放
                this.initialDistance = this.getDistance(event.touches); // 记录初始距离
                event.preventDefault();
            }
            else if (event.touches.length === 1) {
                this.isPanning = true; // 开始平移
                this.startX = event.touches[0].clientX; // 记录起始 X 坐标
                this.startY = event.touches[0].clientY; // 记录起始 Y 坐标
                event.preventDefault(); // 阻止默认行为
            }
        },
        handleTouchMove(event) {
            if (this.isZooming && event.touches.length === 2) {
                const currentDistance = this.getDistance(event.touches); // 当前距离
                const scaleChange = currentDistance / this.initialDistance; // 计算缩放比例
                // 更新缩放比例
                this.scale *= scaleChange; // 更新缩放比例
                this.initialDistance = currentDistance; // 更新初始距离
                // 确保缩放比例在合理范围内
                this.scale = Math.max(this.scale, 0.1); // 最小缩放比例
                this.renderPages(); // 重新渲染页面
            }
            else if (this.isPanning && event.touches.length === 1) {
                const deltaX = event.touches[0].clientX - this.startX; // 计算 X 轴移动距离
                const deltaY = event.touches[0].clientY - this.startY; // 计算 Y 轴移动距离

                // 这里可以实现平移逻辑，例如更新容器的位置
                const container = this.$refs.pdfContainer;
                container.scrollLeft -= deltaX; // 平移容器
                container.scrollTop -= deltaY; // 平移容器

                // 更新起始坐标
                this.startX = event.touches[0].clientX;
                this.startY = event.touches[0].clientY;
            }
        },
        handleTouchEnd(event) {
            if (event.touches.length < 2) {
                this.isZooming = false; // 结束缩放
            }
            if (event.touches.length < 1) {
                this.isPanning = false; // 结束平移
            }
        },
        getDistance(touches) {
            const dx = touches[0].clientX - touches[1].clientX;
            const dy = touches[0].clientY - touches[1].clientY;
            return Math.sqrt(dx * dx + dy * dy);
        }
    },
    mounted() {
        // this.loadPdf(); 
        this.$refs.pdfContainer.addEventListener('touchstart', this.handleTouchStart, false);
        this.$refs.pdfContainer.addEventListener('touchmove', this.handleTouchMove, false);
        this.$refs.pdfContainer.addEventListener('touchend', this.handleTouchEnd, false);
    },
    activated() {
        const container = this.$refs.pdfContainer;

        // 清空容器
        while (container.firstChild) {
            container.removeChild(container.firstChild);
        }
        this.loadPdf();
        const canvas=container.getElementsByTagName('canvas');
        canvas.addEventListener('touchstart', this.handleTouchStart, false);
        canvas.addEventListener('touchmove', this.handleTouchMove, false);
        canvas.addEventListener('touchend', this.handleTouchEnd, false);
    },
    beforeDestroy() {
        const canvas=container.getElementsByTagName('canvas');
        canvas.removeEventListener('touchstart', this.handleTouchStart, false);
        canvas.removeEventListener('touchmove', this.handleTouchMove, false);
        canvas.removeEventListener('touchend', this.handleTouchEnd, false);     
        this.$refs.pdfContainer.removeEventListener('touchstart', this.handleTouchStart, false);
        this.$refs.pdfContainer.removeEventListener('touchmove', this.handleTouchMove, false);
        this.$refs.pdfContainer.removeEventListener('touchend', this.handleTouchEnd, false);
    }
};
</script>

<style>
#pdf-viewer {
    position: relative;
}

.pdf-container {
    overflow-y: scroll;
    height: 80vh;
}

canvas {
    margin: 10px 0;
    /* Space between pages */
    touch-action: none;    /* Prevent default touch actions */
    pointer-events: auto;  /* Enable pointer events for touch */
}
</style>