<template>
    <div id="cad-viewer">
        <div v-if="dxfLoading" class="loading-indicator">正在加载中...</div>
        <div id="dxf-view" ref="canvasContainer" class="canvas-container"></div>
        <div class="controls">
            <button @click="zoomIn">放大</button>
            <button @click="zoomOut">缩小</button>
            <button @click="exportImage3">导出图形</button>
            <button @click="toggleLayer">切换图层</button>
            <button @click="goback">返回</button>
            <!-- 添加图层选择界面 -->
            <div v-if="layersVisible" class="layer-controls">
                <h3>图层控制</h3>
                <ul>
                    <li v-for="layer in layers" :key="layer">
                        <label>
                            <input type="checkbox" :checked="visibleLayers.includes(layer)"
                                @change="toggleLayerVisibility(layer)">
                            {{ layer }}
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import DxfParser from 'dxf-parser';
import { Viewer } from '/public/threedxf/three-dxf.js';
import html2canvas from 'html2canvas';

export default {
    data() {
        return {
            scene: null,
            camera: null,
            renderer: null,
            controls: null,
            dxfLoader: new DxfParser(),
            scale: 1,
            scaleFactor: 5,
            zoomSensitivity:0.1,
            layers: [],
            visibleLayers: [],
            dxfLoading: false,
            layersVisible: false, // 控制图层选择界面的显示
            isZooming: false,
            isDragging: false,
            initialDistance: 0,
        };
    },
    methods: {
        init() {
            this.scene = new THREE.Scene();
            console.log('Scene created:', this.scene);

            this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
            console.log('Camera created:', this.camera);

            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setClearColor(0xffffff, 1);
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            this.$refs.canvasContainer.appendChild(this.renderer.domElement);

            console.log('Canvas container:', this.$refs.canvasContainer);
            console.log('Renderer dom element:', this.renderer.domElement);

            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            this.controls.enableDamping = true;
            //this.controls.dampingFactor = 0.25;
            this.controls.enableZoom = true;
            this.controls.autoRotate = false;
            this.controls.update();
            //this.camera.position.z = 150;

            const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
            this.scene.add(ambientLight);

            const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
            directionalLight.position.set(1, 1, 1).normalize();
            this.scene.add(directionalLight);

            const urlParams = new URLSearchParams(window.location.search);
            const dxfFilePath = urlParams.get('file');
            HTMLCanvasElement.prototype.getContext = function (origFn) {
                return function (type, attributes) {
                    if (type === 'webgl') {
                        attributes = Object.assign({}, attributes, { preserveDrawingBuffer: true });
                    }
                    return origFn.call(this, type, attributes);
                };
            }(HTMLCanvasElement.prototype.getContext);

            if (dxfFilePath) {
                this.loadDXFFile(dxfFilePath);
            }
            this.animate();
            console.log('Scene initialized');
        },

        loadDXF(filePath) {
            console.log('Loading DXF file:', filePath);
            if (!filePath) {
                console.error('File path is empty or invalid');
                return;
            }

            this.dxfLoader.load(filePath, (data) => {
                console.log('DXF file loaded:', data);

                // Assuming `data.entities` contains the entities of the DXF
                data.entities.forEach(entity => {
                    // Create a mesh for each entity
                    const mesh = new THREE.Mesh(entity.geometry, entity.material);
                    mesh.userData.layer = entity.layer; // Assign the layer name to userData
                    this.scene.add(mesh);
                });

                this.layers = this.extractLayers(data);
                this.visibleLayers = [...this.layers]; // Initialize all layers as visible
            }, (xhr) => {
                console.log((xhr.loaded / xhr.total * 100) + '% loaded');
            }, (error) => {
                console.error('Error loading DXF file:', error);
            });
        },
        loadDXFFile(filePath) {
            this.dxfLoading = true; // 开始加载时设置为true
            fetch(filePath)
                .then(response => {
                    if (!response.ok) throw new Error('Network response was not ok');
                    return response.text();
                })
                .then(data => {
                    const event = {
                        target: {
                            result: data
                        }
                    };
                    this.onSuccess(event);
                })
                .catch(error => {
                    console.error('Error loading DXF file:', error);
                    this.dxfLoading = false; // 加载失败时设置为false
                });
        },
        onSuccess(evt) {
            this.dxfLoading = true;
            var fileReader = evt.target;
            if (fileReader.error) return console.log("error onloadend!?");
            var parser = new DxfParser();
            this.dxf = parser.parseSync(fileReader.result);
            document.getElementById('dxf-view').innerHTML = "";

            console.log('Parsed DXF data:', this.dxf);
            // Clear previous meshes if any
            this.scene.clear();

            // Create meshes for each entity in the DXF
            this.dxf.entities.forEach(entity => {
                if (entity.vertices && entity.layer) { // 检查 entity 是否包含 vertices 和 layer 属性
                    // Create a geometry and material based on your DXF entity
                    const geometry = new THREE.BufferGeometry();
                    const vertices = new Float32Array(entity.vertices); // Ensure this is the correct data
                    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

                    const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
                    const mesh = new THREE.Mesh(geometry, material);
                    mesh.userData.layer = entity.layer; // Assign the layer name to userData
                    this.scene.add(mesh);
                } else {
                    console.warn('Entity missing vertices or layer:', entity);
                }
            });
            let width = this.$refs.canvasContainer.offsetWidth;
            let height = this.$refs.canvasContainer.offsetHeight;

           
            // 提取图层并初始化可见图层
            this.layers = this.extractLayers(this.dxf);
            this.visibleLayers = [...this.layers]; // 初始化所有图层都可见
            Viewer(this.dxf, document.getElementById('dxf-view'), width, height, this.visibleLayers,this.loadingClose);
            this.scene.add(this.dxf);
            console.log('DXF object added to scene:', this.dxf);

        },
        extractLayers(dxfData) {
            const layers = [...new Set(dxfData.entities.map(entity => entity.layer))];
            console.log('Extracted layers:', layers);
            return layers;
        },
        zoomIn() {
            console.log('zoomIn');
            const event = new WheelEvent('mousewheel', {
                deltaY: 120, bubbles: true,
                cancelable: true
            });
            this.$refs.canvasContainer.dispatchEvent(event);

        },
        zoomOut() {
            console.log('zoomOut');
            const event = new WheelEvent('mousewheel', {
                deltaY: -120, bubbles: true,
                cancelable: true
            });
            this.$refs.canvasContainer.dispatchEvent(event);
        },

        handleMouseWheel(event) {
            //event.preventDefault();
            var delta = Math.sign(event.deltaY) * 0.1;
            this.camera.fov += delta;
            this.camera.zoom += delta;
            this.camera.updateProjectionMatrix();
            this.renderer.bottom = -23;
            this.controls.update(); // 更新控件状态
            this.renderer.render(this.scene, this.camera);
        },
        handleTouchStart(event) {
            event.preventDefault();
            this.touchStartX = event.touches[0].clientX;
            this.touchStartY = event.touches[0].clientY;
            if (event.touches.length === 2) {
                // 开始缩放
                this.isZooming = true;
                initialDistance = getDistance(event.touches);
            } else if (event.touches.length === 1) {
                // 开始拖动
                this.isDragging = true;

            }
            // 创建并派发鼠标拖动事件
            const mouseDownEvent = new MouseEvent('mousedown', {
                bubbles: true,
                cancelable: true
            });
            this.$refs.canvasContainer.dispatchEvent(mouseDownEvent);



        },
        handleTouchEnd(event) {
            event.preventDefault();
            // 创建并派发鼠标拖动事件
            const mouseUpEvent = new MouseEvent('mouseup', {
                bubbles: true,
                cancelable: true
            });
            this.$refs.canvasContainer.dispatchEvent(mouseUpEvent);
            this.isZooming = false;
            this.isDragging = false;
        },
        handleTouchMove(event) {
            if (this.isZooming && event.touches.length === 2) {
                const currentDistance = getDistance(event.touches);
                const scale = currentDistance / initialDistance; // 计算缩放比例
                console.log('Zooming with scale:', scale);
                const delta = currentDistance - initialDistance;
                const deltax = delta * this.scaleFactor*this.zoomSensitivity; // 缩放因子
                const deltay = delta * this.scaleFactor*this.zoomSensitivity;
                const event = new WheelEvent('mousewheel', {
                    deltaX: deltax, deltay: deltaY, bubbles: true,
                    cancelable: true
                });
                this.$refs.canvasContainer.dispatchEvent(event);
                // 处理缩放逻辑
                event.preventDefault(); // 防止默认的缩放行为
                // 更新初始距离
                initialDistance = currentDistance;
            } else if (this.isDragging && event.touches.length === 1) {
                const touch = event.touches[0];
                console.log('Dragging at position:', touch.clientX, touch.clientY);
                const deltaX = event.touches[0].clientX - this.touchStartX;
                const deltaY = event.touches[0].clientY - this.touchStartY;
                const mouseDownEvent = new MouseEvent('mousedown', {
                    clientX: 0,
                    clientY: 0,
                    bubbles: true,
                    cancelable: true
                });
                this.$refs.canvasContainer.dispatchEvent(mouseDownEvent);

                // 创建并派发鼠标拖动事件
                const mouseMoveEvent = new MouseEvent('mousemove', {
                    clientX: deltaX,
                    clientY: deltaY,
                    bubbles: true,
                    cancelable: true
                });
                this.$refs.canvasContainer.dispatchEvent(mouseMoveEvent);

                const mouseUpEvent = new MouseEvent('mouseup', {
                    bubbles: true,
                    cancelable: true
                });
                this.$refs.canvasContainer.dispatchEvent(mouseUpEvent);
                event.preventDefault(); // 防止默认的滚动行为
            }



            //更新起始触摸位置
            this.touchStartX = event.touches[0].clientX;
            this.touchStartY = event.touches[0].clientY;

            this.renderer.render(this.scene, this.camera);
        },
        onWindowResize() {
            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(window.innerWidth, window.innerHeight);
        },
        toggleLayer() {
            this.layersVisible = !this.layersVisible; // 切换图层选择界面的显示
        },
        toggleLayerVisibility(layer) {
            if (this.visibleLayers.includes(layer)) {
                this.visibleLayers = this.visibleLayers.filter(l => l !== layer);
            } else {
                this.visibleLayers.push(layer);
            }
            console.log('Visible layers updated:', this.visibleLayers); // 添加日志
            this.updateLayerVisibility();
        },
        updateLayerVisibility() {
            this.scene.traverse(child => {
                if (child.isMesh && child.userData.layer) {
                    const isVisible = this.visibleLayers.includes(child.userData.layer);
                    child.visible = isVisible;
                    child.material.visible = isVisible;
                    console.log(`Layer ${child.userData.layer} visibility set to ${isVisible}, current visible: ${child.visible}`); // 添加日志
                }
            });
            document.getElementById('dxf-view').innerHTML = "";
            Viewer(this.dxf, document.getElementById('dxf-view'), window.innerWidth, window.innerHeight, this.visibleLayers, this.loadingClose);
            // this.camera.updateProjectionMatrix();
            // this.renderer.setSize(window.innerWidth, window.innerHeight);
            // this.renderer.render(this.scene, this.camera);// 确保场景重新渲染

        },
        exportImage3() {
            if (!this.dxf) {
                alert('请先加载DXF文件');
                return;
            }

            try {
                const dxfViewElement = document.getElementById('dxf-view');

                html2canvas(dxfViewElement).then(canvas => {
                    const link = document.createElement('a');
                    link.download = 'dxf-preview.png';
                    link.href = canvas.toDataURL('image/png');
                    link.click();
                }).catch(error => {
                    console.error('导出图片失败:', error);
                    alert('导出图片失败');
                });
            } catch (error) {
                console.error('导出图片失败:', error);
                alert('导出图片失败');
            }
        },
        animate() {
            requestAnimationFrame(this.animate);
            this.controls.update();
            this.renderer.render(this.scene, this.camera);
        },
        loadingClose() {
            this.dxfLoading = false;
            console.log('DXF file loaded and rendered successfully');
        },
        goback() {
            history.back();
        },
        getDistance(touches) {
            const dx = touches[0].clientX - touches[1].clientX;
            const dy = touches[0].clientY - touches[1].clientY;
            return Math.sqrt(dx * dx + dy * dy);
        }
    },
    mounted() {
        
        this.$refs.canvasContainer.addEventListener('touchstart', this.handleTouchStart, false);
        this.$refs.canvasContainer.addEventListener('touchmove', this.handleTouchMove, false);
        this.$refs.canvasContainer.addEventListener('touchend', this.handleTouchEnd, false);
    },
    activated() {
        // 清空画布
        document.getElementById('dxf-view').innerHTML = "";
        this.init();
    },
    beforeDestroy() {
        this.$refs.canvasContainer.removeEventListener('touchstart', this.handleTouchStart, false);
        this.$refs.canvasContainer.removeEventListener('touchmove', this.handleTouchMove, false);
        this.$refs.canvasContainer.removeEventListener('touchend', this.handleTouchEnd, false);
    }
};
</script>

<style>
#cad-viewer {
    position: relative;
}

.canvas-container {
    width: 100%;
    height: 100vh;
}

.controls {
    position: absolute;
    top: 10px;
    left: 10px;
}

.layer-controls {
    position: absolute;
    top: 35px;
    right: 10px;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);  
    z-index: 20;  
}

.loading-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
</style>